import React from 'react';

import { useHistory } from 'react-router-dom';

import NavBar from '../ui/NavBar';
import Footer from '../ui/Footer';

export default function VideoEdition() {

    const history = useHistory();

    function navigatePortfolio(e) {
        e.preventDefault();
        
        history.push('/portfolio');
    }

    return (
        <div className="container">
            {/* ---------------------------------------------------------------- */}
            {/* Footer */}
            {/* ---------------------------------------------------------------- */}
            <NavBar />
            
            <div className="jumbotron">

                <h1 className="display-4 text-center">EDIÇÃO DE VÍDEOS E FOTOS</h1>
                    
                <br/>
                
                <p className="lead text-center"><b>Quer ter suas fotos e vídeos em um único vídeo e personalizado?</b> <br/>
                    <b>Você é Youtuber e precisa editar seus vídeos do canal?</b> <br/>
                    <b>Quer divulgar seu produto ou serviço?</b> <br/>
                    <b>Aqui temos a solução!</b> <br/>
                </p>

                <br/>

                <p className="lead text-justify">
                    <b>Edição de foto e vídeo personalizado:</b> transforme seus momentos em recordações emocionantes e inesquecíveis. Perfeito para apresentação de retrospectivas em telões e festas. Convites animados e homenagens.<br/>
                    Faça um túnel do tempo com seus amigos com um conjunto de fotos digitais. Os melhores momentos do seu casamento em um vídeo personalizado com suas fotos. Faça uma retrospectiva do primeiro ano do(a) seu(ua) filho(a)! Compartilhe as fotos daquele churrasco ou confraternização com os amigos de uma forma criativa e diferente. Auxiliamos na publicação do seu vídeo nas redes sociais. E muito mais... na verdade, o que sua imaginação permitir. Consulte-nos, envie-nos suas ideias e nós as transformamos em realidade.
                </p>

                <br/>

                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12">
                            <a className="d-block mb-4 h-100">
                                <div className="embed-responsive embed-responsive-16by9">
                                    <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/xM-adQqVhpg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" ></iframe>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <a className="d-block mb-4 h-100">
                                <div className="embed-responsive embed-responsive-16by9">
                                    <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/9hNC1veWS6k" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" ></iframe>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

                <br/>

                <p className="lead text-justify"> 
                    Solicite um orçamento ou mais informações por <a href="https://wa.me/5511998727617?text=Ol%C3%A1,%20gostaria%20de%20saber%20mais%20sobre%20os%20servi%C3%A7os%20da%20DronDron." target="_blank" rel="noopener noreferrer">whatsapp</a>. 
                    Ou então, fique a vontade para deixar uma <a href="mailto:drondron.contact@gmail.com" target="_blank" rel="noopener noreferrer">mensagem</a> com suas dúvidas, sugestões críticas, ou até mesmo curiosidade. Será um prazer atender. Deixe seu e-mail, que retornaremos em breve.
                </p>

                <br/>

                <p className="lead text-justify">
                    <b>Edição de vídeo geral:</b> Edições para canais de Youtube, com suas vinhetas, banners, logos e tudo que você precisar. Garanta a divulgação do seu produto ou serviço através de uma campanha de marketing com vídeos. Veja exemplos! Com inclusão de arte de abertura, finalização. Incluímos logomarca e criamos efeitos incríveis para a divulgação do seu negócio. Lançamento e campanhas de marketing. Animações com legendas geram vídeos que prendem o público e direcionam o foco para seu negócio. Entre em contato e conte-nos o que você precisa. Temos todas ferramentas necessárias para produzir seus vídeos.
                </p>

                <br/>

                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12">
                            <a className="d-block mb-4 h-100">
                                <div className="embed-responsive embed-responsive-16by9">
                                    <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/cymZxYy0NiQ" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" ></iframe>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <a className="d-block mb-4 h-100">
                                <div className="embed-responsive embed-responsive-16by9">
                                    <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/evwliWEtHJI" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" ></iframe>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

                <br/>

                <p className="lead text-justify">
                    Para ver mais dos nossos trabalhos, por favor, visite nosso <a href="" onClick={navigatePortfolio}>portfolio</a>.
                </p>
                
                {/* ---------------------------------------------------------------- */}
                {/* Footer */}
                {/* ---------------------------------------------------------------- */}
                <hr className="my-4" />
                <Footer />

            </div>

            
        </div>
    );
}