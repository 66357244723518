import React from 'react';

import ImgFacebook from '../media/icons/facebook.webp';
import ImgInstagram from '../media/icons/instagram.webp';

import ImgIconEmail from '../media/icons/email.png'
import ImgIconWhats from '../media/icons/whats.png'

export default function Footer() {
    return (
        <footer className="page-footer font-small special-color-dark pt-4">

            <div className="container">
            
                <ul className="list-unstyled list-inline text-center ">
                    <li className="list-inline-item justify-content-between align-items-center" >
                        <img src={ImgIconWhats} width="30" alt="Whatsapp" />
                    </li>
                    <li className="list-inline-item justify-content-between align-items-center">
                        <p><a href="https://wa.me/5511998727617?text=Ol%C3%A1,%20gostaria%20de%20saber%20mais%20sobre%20os%20servi%C3%A7os%20da%20DronDron." target="_blank" rel="noopener noreferrer">+55 (11) 99872-7617</a></p>
                    </li>
                    <br/>
                    <li className="list-inline-item justify-content-between align-items-center" >
                        <img src={ImgIconEmail} width="32" alt="Whatsapp" />
                    </li>
                    <li className="list-inline-item justify-content-between align-items-center">
                        <p><a href="mailto:drondron.contact@gmail.com" target="_blank" rel="noopener noreferrer">drondron.contact@gmail.com</a></p>
                    </li>
                </ul>

                <ul className="list-unstyled list-inline text-center">
                    <li className="list-inline-item">
                        <a href="https://www.facebook.com/drondron22/" target="_blank" rel="noopener noreferrer">
                            <img src={ImgFacebook} alt="Facebook" width="50" style={{cursor: "pointer"}}  />
                        </a>
                    </li>
                    <li className="list-inline-item">
                        <a href="https://www.instagram.com/drondron22/" target="_blank" rel="noopener noreferrer">
                            <img src={ImgInstagram} alt="Instagram" width="50" style={{cursor: "pointer"}}  />
                        </a>
                    </li>
                    <br/>
                    <li className="list-inline-item">
                        <div className="footer-copyright text-center py-3">© 2020 - DronDron</div>
                    </li>
                </ul>

            </div>

        </footer>
    );
}