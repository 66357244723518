import React from 'react';

import NavBar from '../ui/NavBar';
import Footer from '../ui/Footer';

import ModalImage from "react-modal-image";

import ImgPicFair from '../media/icons/picfair.webp';
import ImgYoutube from '../media/icons/youtube.png';
import ImgMomento360 from '../media/icons/momento360.png'
import ImgDrone from '../media/icons/drone.png'

import Galery001 from '../media/Galery_001.webp'
import Galery005 from '../media/Galery_005.webp'
import Galery006 from '../media/Galery_006.webp'
import Galery009 from '../media/Galery_009.webp'

import Galery001_tn from '../media/Galery_001_tn.webp'
import Galery005_tn from '../media/Galery_005_tn.webp'
import Galery006_tn from '../media/Galery_006_tn.webp'
import Galery009_tn from '../media/Galery_009_tn.webp'

export default function Portfolio() {

    return (
        <div className="container">
            
            {/* ---------------------------------------------------------------- */}
            {/* Navigation Bar */}
            {/* ---------------------------------------------------------------- */}
            <NavBar />

            <div className="jumbotron">

                {/* ---------------------------------------------------------------- */}
                {/* YOUTUBE - PLAYLISTS */}
                {/* ---------------------------------------------------------------- */}

                <div className="media">
                    <img className="mr-3" src={ImgYoutube} width="75" alt="Youtube" /> 
                    <div className="media-body">
                        <h5 className="mt-0">Youtube</h5>
                        <p className="text-justify">
                            Visite o canal <a href="https://www.youtube.com/channel/UCQAlQ83J7xj5hRsIRzvZceQ?view_as=subscriber" target="_blank" rel="noopener noreferrer">aqui</a> para ver todos os vídeos, ou veja as playlists abaixo. Elas estão categorizadas para facilitar a identificação do trabalho que você precisa. Tem edição pra Youtubers, retrospectivas, marketing, captura de imagens e vídeos aéreos. 
                        </p>
                    </div>
                </div>

                <br/>
                <br/>

                <div className="container">
                    <div className="row">
                        {/* DronDron - VÍDEOS PROMOCIONAIS */}
                        <div className="col-lg-6 col-md-6 col-12">
                            <a className="d-block mb-4 h-100">
                            <p className="text-justify">
                                Edição para youtubers, marketing e vídeos promocionais.
                            </p>
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe className="embed-responsive-item" width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PL5Mb4BsQg09fW8FaBrViSX7wYGC8jmKMj" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            </a>
                        </div>
                        {/* DronDron - CINEMINHA */}
                        <div className="col-lg-6 col-md-6 col-12">
                            <p className="text-justify">
                                Retrospectivas para casamentos, aniversários e formaturas.
                            </p>
                            <a className="d-block mb-4 h-100">
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe className="embed-responsive-item" width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PL5Mb4BsQg09fJ3pi1YOgLWzeGwUU-EfV3" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            </a>
                        </div>
                        {/* DronDron - EDIÇÃO DE VÍDEO */}
                        <div className="col-lg-6 col-md-6 col-12">
                            <p className="text-justify">
                                Álbuns de fotos e vídeos digitais com música e efeitos.
                            </p>
                            <a className="d-block mb-4 h-100">
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe className="embed-responsive-item" width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PL5Mb4BsQg09fz8De1I3bZgLYU4Gmy8j9I" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            </a>
                        </div>
                        {/* DronDron - Drone Jobs */}
                        <div className="col-lg-6 col-md-6 col-12">
                            <p className="text-justify">
                                Capturas de fotos e vídeos aéreos com drone.
                            </p>
                            <a className="d-block mb-4 h-100">
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe className="embed-responsive-item" width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PL5Mb4BsQg09f0kqN9O7a6wc1TId5zRUOi" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            </a>
                        </div>
                    </div>
                </div>

                <hr className="my-4" />  

                <br/>

                {/* ---------------------------------------------------------------- */}
                {/* MOMENTO 360 - GALLERY */}
                {/* ---------------------------------------------------------------- */}

                <div className="media">
                    <img className="mr-3" src={ImgMomento360} width="75" alt="Youtube" /> 
                    <div className="media-body">
                        <h5 className="mt-0">Momento 360</h5>
                        <p className="text-justify">
                            Fotos e vídeos 360 para tour virtual, lançamento e divulgação de imóveis. Divulgação de espaços para festas e buffets. Marketing de restaurantes, hotéis, clubes e casas de shows. Visite o portfolio completo <a href="https://momento360.com/e/uc/08aa77a3e6604692ba656cb89cf5b7d8?utm_campaign=embed&utm_source=other" target="_blank" rel="noopener noreferrer">aqui</a>.
                        </p>                   
                    </div>
                </div>

                <br/>
                <br/>

                <div className="embed-responsive embed-responsive-16by9">
                    <iframe className="embed-responsive-item" width="560" height="315" src="https://momento360.com/e/uc/08aa77a3e6604692ba656cb89cf5b7d8?utm_campaign=embed&utm_source=other" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>

                <hr className="my-4" />  

                <br/>
                <br/>

                {/* ---------------------------------------------------------------- */}
                {/* PICFAIR - GALLERY */}
                {/* ---------------------------------------------------------------- */}
                
                <div className="media">
                    <img className="mr-3" src={ImgPicFair} width="75" alt="Youtube" /> 
                    <div className="media-body">
                        <h5 className="mt-0">Picfair</h5>
                        <p className="text-justify">
                            Fotografia aérea profissional, com drone e de alta qualidade. Veja galeria completa <a href="https://jubatistim.picfair.com/" target="_blank" rel="noopener noreferrer">aqui</a>.
                        </p>                
                    </div>
                </div>

                <br/>
                <br/>

                <div className="container">

                    <div className="row text-center text-lg-left">

                        {/* Thumb 600px - 5 px border */}
                        <div className="col-lg-3 col-md-4 col-6">
                            <a className="d-block mb-4 h-100">
                                <ModalImage
                                    small={Galery001_tn}
                                    large={Galery001}
                                    alt="Hello World!"
                                />
                            </a>
                        </div>

                        <div className="col-lg-3 col-md-4 col-6">
                            <a className="d-block mb-4 h-100">
                                <ModalImage
                                    small={Galery009_tn}
                                    large={Galery009}
                                    alt="Hello World!"
                                />
                            </a>
                        </div>

                        <div className="col-lg-3 col-md-4 col-6">
                            <a className="d-block mb-4 h-100">
                                <ModalImage
                                    small={Galery005_tn}
                                    large={Galery005}
                                    alt="Hello World!"
                                />
                            </a>
                        </div>

                        <div className="col-lg-3 col-md-4 col-6">
                            <a className="d-block mb-4 h-100">
                                <ModalImage
                                    small={Galery006_tn}
                                    large={Galery006}
                                    alt="Hello World!"
                                />
                            </a>
                        </div>

                    </div>
                    
                </div>

                <hr className="my-4" />  

                <br/>

                {/* ---------------------------------------------------------------- */}
                {/* DRONE DEPLOY */}
                {/* ---------------------------------------------------------------- */}

                <div className="media">
                    <img className="mr-3" src={ImgDrone} width="85" alt="Youtube" /> 
                    <div className="media-body">
                        <h5 className="mt-0">Aerofotogrametria</h5>
                        <p className="text-justify">
                            Geração de produtos cartográficos, cartas planialtimétricas, MDT, MDS, curvas de nível, vetorização e muito mais. Mais detalhes do trabalho abaixo  <a href="https://www.dronedeploy.com/app2/data/5e9504a333e5e72aaaf13f2c;jwt_token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJleHAiOjI1MzQwMjMwMDc5OSwiaWQiOiI1ZTk1MDRhMzMzZTVlNzJhYWFmMTNmMmMiLCJzY29wZSI6WyI5M2RiMTRiZTk5X0FCOUZCQ0M0QzZPUEVOUElQRUxJTkUiXSwidHlwZSI6IlJlYWRPbmx5UGxhbiIsIm92ZXJsYXlfZm9sZGVyX2lkIjoiNWU5NTAyOTkzM2U1ZTcyYWFhZjEzZjI2In0.QfreaY8zJ69uwkUuj4MwtlwasT23B5WyaZCcGH1EcKCNENa1NjcPzc0az71AkjuTJkB7rXheABvqrAyUrlF9ew">aqui</a>. Além do DroneDeploy na DronDron trabalhamos com outros softwares, não automatizados, possibilitando a entrega do material cartográfico com mais recursos e maior flexibilidade.
                        </p>                   
                    </div>
                </div>

                <br/>

                <div className="embed-responsive embed-responsive-16by9">
                    <iframe className="embed-responsive-item" width='500' height='500' src='https://www.dronedeploy.com/app2/data/5e9504a333e5e72aaaf13f2c;jwt_token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJleHAiOjI1MzQwMjMwMDc5OSwiaWQiOiI1ZTk1MDRhMzMzZTVlNzJhYWFmMTNmMmMiLCJzY29wZSI6WyI5M2RiMTRiZTk5X0FCOUZCQ0M0QzZPUEVOUElQRUxJTkUiXSwidHlwZSI6IlJlYWRPbmx5UGxhbiIsIm92ZXJsYXlfZm9sZGVyX2lkIjoiNWU5NTAyOTkzM2U1ZTcyYWFhZjEzZjI2In0.QfreaY8zJ69uwkUuj4MwtlwasT23B5WyaZCcGH1EcKCNENa1NjcPzc0az71AkjuTJkB7rXheABvqrAyUrlF9ew'></iframe>
                </div>

                <hr className="my-4" />  
                
                {/* ---------------------------------------------------------------- */}
                {/* FACEBOOK AND INSTAGRAM */}
                {/* ---------------------------------------------------------------- */}
                <p class="h4">Siga-nos nas redes sociais</p>
                <br/>
                <p className="lead text-justify">
                    <b>
                        Para acompanhar nossos trabalhos, também siga-nos nas redes sociais! Sempre que tiver alguma dúvida, ou se o seu caso não se enquandra nos casos descritos aqui, fiqua a vontade para nos consultar. Será um prazer ajudar e tirar suas dúvidas. Até breve!
                    </b>
                </p>

                <hr className="my-4" /> 

                {/* ---------------------------------------------------------------- */}
                {/* Footer */}
                {/* ---------------------------------------------------------------- */}
                <Footer />

            </div>

        </div>
    );
}