import React from 'react';

import { Link, useHistory } from 'react-router-dom';

import ImgDronDronLogo from '../media/DronDron_LOGO.webp';

export default function NavBar() {

    const history = useHistory();

    function navigateHome(e) {
        e.preventDefault();
        
        history.push('/');
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            
            <img className="navbar-brand" src={ImgDronDronLogo} alt="DronDron Logo" width="200" onClick={navigateHome} style={{cursor: "pointer"}} />

            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">

                <ul className="navbar-nav mr-auto">

                    <li className="nav-item active">
                        <Link className="nav-link" to="/videoedition">
                            Edição de Vídeos
                            <span className="sr-only">(current)</span>
                        </Link>
                    </li>

                    <li className="nav-item active">
                        <Link className="nav-link" to="/photovideo">
                            Fotos e Vídeos
                            <span className="sr-only">(current)</span>
                        </Link>
                    </li>

                    <li className="nav-item active">
                        <Link className="nav-link" to="/topography">
                            Topografia e Medições
                            <span className="sr-only">(current)</span>
                        </Link>
                    </li>

                    <li className="nav-item active">
                        <Link className="nav-link" to="/inspection">
                            Inspeções
                            <span className="sr-only">(current)</span>
                        </Link>
                    </li>                    

                    <li className="nav-item active">
                        <Link className="nav-link" to="/portfolio">
                            Portfolio
                            <span className="sr-only">(current)</span>
                        </Link>
                    </li>

                </ul>

                <a href="mailto:drondron.contact@gmail.com" target="_blank" rel="noopener noreferrer">
                    <button type="button" className="btn btn-outline-dark">
                        Contato
                    </button>
                </a>

            </div>

        </nav>

    );
}