import React from 'react';

import { useHistory } from 'react-router-dom';

import NavBar from '../ui/NavBar';
import Footer from '../ui/Footer';

import ModalImage from "react-modal-image";

import Galery001 from '../media/Galery_001.webp'
import Galery002 from '../media/Galery_002.webp'
import Galery003 from '../media/Galery_003.webp'
import Galery004 from '../media/Galery_004.webp'
import Galery005 from '../media/Galery_005.webp'
import Galery006 from '../media/Galery_006.webp'
import Galery007 from '../media/Galery_007.webp'
import Galery008 from '../media/Galery_008.webp'
import Galery009 from '../media/Galery_009.webp'
import Galery010 from '../media/Galery010.webp'
import Galery011 from '../media/Galery011.webp'
import Galery012 from '../media/Galery012.webp'

import Galery001_tn from '../media/Galery_001_tn.webp'
import Galery002_tn from '../media/Galery_002_tn.webp'
import Galery003_tn from '../media/Galery_003_tn.webp'
import Galery004_tn from '../media/Galery_004_tn.webp'
import Galery005_tn from '../media/Galery_005_tn.webp'
import Galery006_tn from '../media/Galery_006_tn.webp'
import Galery007_tn from '../media/Galery_007_tn.webp'
import Galery008_tn from '../media/Galery_008_tn.webp'
import Galery009_tn from '../media/Galery_009_tn.webp'
import Galery010_tn from '../media/Galery010_tn.webp'
import Galery011_tn from '../media/Galery011_tn.webp'
import Galery012_tn from '../media/Galery012_tn.webp'

import ImgTinyWorldSample01 from '../media/TinyWorld_Sample_01.webp'
import ImgPanoSample01 from '../media/Pano_Sample_01.webp'

export default function PhotoVideo() {

    const history = useHistory();

    function navigatePortfolio(e) {
        e.preventDefault();
        
        history.push('/portfolio');
    }

    return (
        <div className="container">
            
            {/* ---------------------------------------------------------------- */}
            {/* Navigation Bar */}
            {/* ---------------------------------------------------------------- */}
            <NavBar />

            <div className="jumbotron">
                
                {/* ---------------------------------------------------------------- */}
                {/* Vídeos e edições*/}
                {/* ---------------------------------------------------------------- */}
                <h1 className="display-4">VÍDEOS</h1>
                
                <br/>
                
                <p className="lead text-justify"><b>Serviço de vídeos com drone:</b> confira abaixo alguns exemplos de vídeos que podem ser elaborados com a DronDron. Os vídeos representam um pouco do universo de possibilidades para produzir um vídeo exclusivo para seu evento, festa, divulgação ou campanha de marketing. Se quiser ver mais exemplos, por favor, visite nosso <a href="" onClick={navigatePortfolio}>portfolio</a>.</p>
                
                <br/>

                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12">
                            <a className="d-block mb-4 h-100">
                                <div className="embed-responsive embed-responsive-16by9">
                                    <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/rWfUUsAW9kA" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" ></iframe>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <a className="d-block mb-4 h-100">
                                <div className="embed-responsive embed-responsive-16by9">
                                    <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/hV5nnusYEWs" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" ></iframe>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

                <br/>

                <p className="lead text-justify">Modos de filmagens com controle automático de eixos resolução 4k, timelapse e hyperlapse, edição profissional dos vídeos com música e efeitos especiais.
                    O custo desse serviço varia de acordo com a localização do evento e duração das tomadas. Os voos são realizados nos finais de semana, durante a semana os voos são realizados por parceiros. Verifique a disponibilidade e solicite um orçamento por <a href="https://wa.me/5511998727617?text=Ol%C3%A1,%20gostaria%20de%20saber%20mais%20sobre%20os%20servi%C3%A7os%20da%20DronDron." target="_blank" rel="noopener noreferrer">whatsapp</a>.
                    Ou então, fique a vontade para deixar uma <a href="mailto:drondron.contact@gmail.com" target="_blank" rel="noopener noreferrer">mensagem</a> com suas dúvidas, sugestões críticas, ou até mesmo curiosidade. Será um prazer atender. Deixe seu e-mail, que retornaremos em breve.
                </p>

                <hr className="my-4" />              
                                
                <br/>

                {/* ---------------------------------------------------------------- */}
                {/* FOTOGRAFIAS*/}
                {/* ---------------------------------------------------------------- */}
                <h1 className="display-4">FOTOS</h1>
                
                <br/>
                
                <p className="lead text-justify"><b>Serviço de fotografia com Drone:</b> Ideal para fazer cobertura fotográfica do seu evento, festa ou celebração. Indispensável para produção de material promocional, lançamento de imóveis, condomínios ou qualquer tipo de campanha de marketing. As fotos aéreas são um diferencial!
​                   Para seu evento produzimos fotos incríveis com edição de álbum digital com música. Linda e emocionante recordação. 
                   Fotos em 12MP de diferentes ângulos e altitudes, vídeos em 4K, fotos panorâmicas 360º (com tiny world) e 180º, panorâmicas horizontal e vertical.
                   Solicite um orçamento ou mais informações por <a href="https://wa.me/5511998727617?text=Ol%C3%A1,%20gostaria%20de%20saber%20mais%20sobre%20os%20servi%C3%A7os%20da%20DronDron." target="_blank" rel="noopener noreferrer">whatsapp</a>. 
                   Ou então, fique a vontade para deixar uma <a href="mailto:drondron.contact@gmail.com" target="_blank" rel="noopener noreferrer">mensagem</a> com suas dúvidas, sugestões críticas, ou até mesmo curiosidade. Será um prazer atender. Deixe seu e-mail, que retornaremos em breve.
                </p>
                
                <br/>

                {/* ---------------------------------------------------------------- */}
                {/* Photo Galery */}
                {/* ---------------------------------------------------------------- */}
                <div className="container">

                    <div className="row text-center text-lg-left">

                        {/* Thumb 600px - 5 px border */}
                        <div className="col-lg-3 col-md-4 col-6">
                            <a className="d-block mb-4 h-100">
                                <ModalImage
                                    small={Galery001_tn}
                                    large={Galery001}
                                    alt="Hello World!"
                                />
                            </a>
                        </div>

                        <div className="col-lg-3 col-md-4 col-6">
                            <a className="d-block mb-4 h-100">
                                <ModalImage
                                    small={Galery002_tn}
                                    large={Galery002}
                                    alt="Hello World!"
                                />
                            </a>
                        </div>

                        <div className="col-lg-3 col-md-4 col-6">
                            <a className="d-block mb-4 h-100">
                                <ModalImage
                                    small={Galery003_tn}
                                    large={Galery003}
                                    alt="Hello World!"
                                />
                            </a>
                        </div>

                        <div className="col-lg-3 col-md-4 col-6">
                            <a className="d-block mb-4 h-100">
                                <ModalImage
                                    small={Galery004_tn}
                                    large={Galery004}
                                    alt="Hello World!"
                                />
                            </a>
                        </div>

                        <div className="col-lg-3 col-md-4 col-6">
                            <a className="d-block mb-4 h-100">
                                <ModalImage
                                    small={Galery005_tn}
                                    large={Galery005}
                                    alt="Hello World!"
                                />
                            </a>
                        </div>

                        <div className="col-lg-3 col-md-4 col-6">
                            <a className="d-block mb-4 h-100">
                                <ModalImage
                                    small={Galery006_tn}
                                    large={Galery006}
                                    alt="Hello World!"
                                />
                            </a>
                        </div>

                        <div className="col-lg-3 col-md-4 col-6">
                            <a className="d-block mb-4 h-100">
                                <ModalImage
                                    small={Galery007_tn}
                                    large={Galery007}
                                    alt="Hello World!"
                                />
                            </a>
                        </div>

                        <div className="col-lg-3 col-md-4 col-6">
                            <a className="d-block mb-4 h-100">
                                <ModalImage
                                    small={Galery008_tn}
                                    large={Galery008}
                                    alt="Hello World!"
                                />
                            </a>
                        </div>

                        <div className="col-lg-3 col-md-4 col-6">
                            <a className="d-block mb-4 h-100">
                                <ModalImage
                                    small={Galery009_tn}
                                    large={Galery009}
                                    alt="Hello World!"
                                />
                            </a>
                        </div>

                        <div className="col-lg-3 col-md-4 col-6">
                            <a className="d-block mb-4 h-100">
                                <ModalImage
                                    small={Galery010_tn}
                                    large={Galery010}
                                    alt="Hello World!"
                                />
                            </a>
                        </div>

                        <div className="col-lg-3 col-md-4 col-6">
                            <a className="d-block mb-4 h-100">
                                <ModalImage
                                    small={Galery011_tn}
                                    large={Galery011}
                                    alt="Hello World!"
                                />
                            </a>
                        </div>

                        <div className="col-lg-3 col-md-4 col-6">
                            <a className="d-block mb-4 h-100">
                                <ModalImage
                                    small={Galery012_tn}
                                    large={Galery012}
                                    alt="Hello World!"
                                />
                            </a>
                        </div>

                    </div>
                    
                </div>

                <p className="lead text-justify">
                    Para ver mais fotos dos nossos trabalhos, por favor, visite nosso <a href="" onClick={navigatePortfolio}>portfolio</a>.
                </p>
               
                <hr className="my-4" />              
                                
                <br/>
                
                {/* ---------------------------------------------------------------- */}
                {/* Tiny World */}
                {/* ---------------------------------------------------------------- */}
                <div className="container">

                    <h1 className="display-4 text-center">Tiny World</h1>

                    <br/>

                    <img className="img-fluid" src={ImgTinyWorldSample01} alt="TinyWorld_Sample_01" />
                    
                    <br/>   

                </div>

                <br/>
                <br/>

                <p className="lead text-justify">
                    Para ver mais fotos dos nossos trabalhos, por favor, visite nosso <a href="" onClick={navigatePortfolio}>portfolio</a>.
                </p>

                <hr className="my-4" />

                {/* ---------------------------------------------------------------- */}
                {/* 360 Photos*/}
                {/* ---------------------------------------------------------------- */}
                <div className="container">
                    
                    <h1 className="display-4 text-center">Fotos 360</h1>

                    <br/>

                    <div className="embed-responsive embed-responsive-16by9">
                        <iframe className="embed-responsive-item" src="https://momento360.com/e/u/d1093a764c714ad1b554c99b4a862075?utm_campaign=embed&utm_source=other&utm_medium=other&heading=0&pitch=0&field-of-view=75" allowfullscreen></iframe>
                    </div>

                    <br/>                 

                </div>

                <p className="lead text-justify">
                    Para ver mais fotos dos nossos trabalhos, por favor, visite nosso <a href="" onClick={navigatePortfolio}>portfolio</a>.
                </p>

                <br/>

                <hr className="my-4" />

                <br/>

                {/* ---------------------------------------------------------------- */}
                {/* Panoramica*/}
                {/* ---------------------------------------------------------------- */}
                <div className="container">

                    <h1 className="display-4 text-center">Fotos Panorâmicas</h1>

                    <br/>

                    <img className="img-fluid" src={ImgPanoSample01} alt="Pano_Sample_01" width="1000" />
                    
                    <br/>       

                </div>

                <br/>
                <br/>

                <p className="lead text-justify">
                    Para ver mais fotos dos nossos trabalhos, por favor, visite nosso <a href="" onClick={navigatePortfolio}>portfolio</a>.
                </p>

                <hr className="my-4" />

                <br/>               

                {/* ---------------------------------------------------------------- */}
                {/* Footer */}
                {/* ---------------------------------------------------------------- */}
                <Footer />

            </div>
        </div>
    );
}