import React, { useEffect } from 'react';

import ReactGA from 'react-ga';

import './App.css';
import Routes from'./routes';


function App() {

  useEffect(() => {
    ReactGA.initialize('UA-138305011-1')
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="container">
      <Routes />
    </div>
  );
}

export default App;
