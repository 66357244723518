import React from 'react';

import NavBar from '../ui/NavBar';
import Footer from '../ui/Footer';

import ImgAerofotogrametria_MDT from '../media/Aerofotogrametria_MDT.webp'
import ImgAerofotogrametria_Report from '../media/Aerofotogrametria_Report.webp'
import ImgAerofotogrametria_3dModel from '../media/Aerofotogrametria_3dModel.webp'
import ImgAerofotogrametria_FotosAereas from '../media/Aerofotogrametria_FotosAereas.png'
import ImgAerofotogrametria_MapaCoordenadas from '../media/Aerofotogrametria_MapaCoordenadas.webp'

export default function Topography() {

    return (
        <div className="container">
            
            {/* ---------------------------------------------------------------- */}
            {/* Navbar*/}
            {/* ---------------------------------------------------------------- */}
            <NavBar />
            
            <div className="jumbotron">
                
                {/* ---------------------------------------------------------------- */}
                {/* TOPOGRAFIA E MEDIÇÕES*/}
                {/* ---------------------------------------------------------------- */}
                <h1 className="display-4 text-center">TOPOGRAFIA E MEDIÇÕES</h1>
                <h1 className="display-4 text-center">URBANAS E RURAIS</h1>
                
                <br/>
                
                <p className="lead text-justify">
                    Solução em mapeamento de áreas, economia e rapidez. Relatórios de medida e divisão de área, planejamento, mapas com coordenadas, modelos tridimensionais, carta planialtimetrica em ortofotomosaico, curvas de nível, modelo digital de terreno, superfície e muito mais! Confira mais informações abaixo ou entre em contato por <a href="https://wa.me/5511998727617?text=Ol%C3%A1,%20gostaria%20de%20saber%20mais%20sobre%20os%20servi%C3%A7os%20da%20DronDron." target="_blank" rel="noopener noreferrer">whatsapp</a>. Qualidade, excelência, economia, rapidez e segurança.
                    Se preferir, deixe uma <a href="mailto:drondron.contact@gmail.com" target="_blank" rel="noopener noreferrer">mensagem</a> com suas dúvidas, que nós retornamos o contato em breve.
                </p>

                <p className="lead text-justify">Produtos cartográficos:</p>

                <ul>
                    <li className="lead" >Fotos em GeoTIFF georreferenciadas</li>
                    <li className="lead" >Mapas com coordenadas</li>
                    <li className="lead" >Cartas planimetricas em ortofotomosaico</li>
                    <li className="lead" >Cartas planialtimetricas em DEM (Digital Elevation Model)</li>
                    <li className="lead" >MDT - Modelo digital de terreno</li>
                    <li className="lead" >Curvas de nível</li>
                    <li className="lead" >MDS - Modelo digital de superfície</li>
                    <li className="lead" >Modelo tridimensional de terreno</li>
                    <li className="lead" >Relatório de medições personalizado para cada estudo</li>
                </ul>

                <p className="lead text-justify">
                    Para ver um exemplo desse trabalho, com os entregáveis de produtos cartográficos, clique <a href="https://www.dronedeploy.com/app2/data/5e9504a333e5e72aaaf13f2c;jwt_token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJleHAiOjI1MzQwMjMwMDc5OSwiaWQiOiI1ZTk1MDRhMzMzZTVlNzJhYWFmMTNmMmMiLCJzY29wZSI6WyI5M2RiMTRiZTk5X0FCOUZCQ0M0QzZPUEVOUElQRUxJTkUiXSwidHlwZSI6IlJlYWRPbmx5UGxhbiIsIm92ZXJsYXlfZm9sZGVyX2lkIjoiNWU5NTAyOTkzM2U1ZTcyYWFhZjEzZjI2In0.QfreaY8zJ69uwkUuj4MwtlwasT23B5WyaZCcGH1EcKCNENa1NjcPzc0az71AkjuTJkB7rXheABvqrAyUrlF9ew" target="_blank" rel="noopener noreferrer">aqui</a>.
                </p>

                <br/>

                <img className="img-fluid" src={ImgAerofotogrametria_MapaCoordenadas} alt="Mapa Coordenadas" />

                <br/>
                <br/>

                <p className="lead text-justify">
                    <b>Serviço de medições com drone:</b> A aerofotogrametria é realizada em duas etapas. O voo e o processamento de dados.
                </p>
                <p className="lead text-justify">
                    No voo uma aeronave equipada com <b>câmeras fotográficas métricas de alta resolução e GPS</b> percorre o território fotografando-o verticalmente, seguindo alguns preceitos técnicos como: ângulo máximo de cambagem, sobreposição frontal e lateral entre as fotos. O voo dura entre 15 minutos e 1 hora, dependendo do tamanho da área medida.
                </p>

                <br/>

                <img className="img-fluid" src={ImgAerofotogrametria_FotosAereas} alt="Fotos Aéreas" />

                <br/>
                <br/>

                <p className="lead text-justify">
                    Na fase de processamento de dados as fotos são analisadas com os dados posicionamento, superposição e alinhamento. Softwares de processamento de imagens são utilizados para gerar nuvens de pontos e mapas de profundidade em um espaço tridimensional. Após essa fase são geradas malhas e texturas. Nesse ponto se obtém o <b>modelo em 3D</b> da área em estudo.
                </p>

                <br/>

                <img className="img-fluid rounded mx-auto d-block" src={ImgAerofotogrametria_3dModel} alt="Modelo 3D" />

                <br/>
                <br/>

                <p className="lead text-justify">
                    O passo seguinte é a geração do <b>DEM (Digital Elevation Model)</b>. Utilizando os mapas de profundidade é possível gerar o perfil de elevação (MDT - Modelo digital de terreno e MDS - Modelo digital de superfície) e curvas de nível de toda área medida. Esse produto é gerado em GeoTIFF, isso significa que além de uma imagem de alta resolução é possível utilizar em qualquer serviço <b>GIS (Geographic Information Systems)</b>, para <b>geração de mapas, cartesiano de coordenadas, mapas com distância, áreas e volume</b>, entre outras diversas aplicações. Consulte-nos e envie seu requisito. Seguramente a DronDron vai te atender!
                </p>

                <br/>

                <img className="img-fluid rounded mx-auto d-block" src={ImgAerofotogrametria_MDT} alt="Modelo 3D" />

                <br/>
                <br/>

                <p className="lead text-justify">
                    Por último é gerada a ortofoto, que é a representação fotográfica de toda área medida. Nessa foto se realizam as medidas de perímetro, área e volume. Esse produto é gerado em GeoTIFF, isso significa que além de uma imagem de alta resolução é possível utilizar em qualquer serviço <p>GIS (Geographic Information Systems)</p>, para <b>geração de mapas, cartesiano de coordenadas, mapas com distância, áreas e volume</b>, entre outras diversas aplicações. Consulte-nos e envie seu requisito. Seguramente a DronDron vai te atender!
                </p>
                <p className="lead text-justify">
                    A fase de processamento de dados dura em torno de 1 semana, dependendo do tamanho da área medida.
                </p>

                <br/>

                <img className="img-fluid rounded mx-auto d-block" src={ImgAerofotogrametria_Report} alt="Modelo 3D" />

                <br/>
                <br/>

                <p className="lead text-justify">
                    Para ver um exemplo desse trabalho, com os entregáveis de produtos cartográficos, clique <a href="https://www.dronedeploy.com/app2/data/5e9504a333e5e72aaaf13f2c;jwt_token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJleHAiOjI1MzQwMjMwMDc5OSwiaWQiOiI1ZTk1MDRhMzMzZTVlNzJhYWFmMTNmMmMiLCJzY29wZSI6WyI5M2RiMTRiZTk5X0FCOUZCQ0M0QzZPUEVOUElQRUxJTkUiXSwidHlwZSI6IlJlYWRPbmx5UGxhbiIsIm92ZXJsYXlfZm9sZGVyX2lkIjoiNWU5NTAyOTkzM2U1ZTcyYWFhZjEzZjI2In0.QfreaY8zJ69uwkUuj4MwtlwasT23B5WyaZCcGH1EcKCNENa1NjcPzc0az71AkjuTJkB7rXheABvqrAyUrlF9ew" target="_blank" rel="noopener noreferrer">aqui</a>. O trabalho não se limita a apenas esses artefatos. Geramos também os produtos personalizados e de acordo as necessidades do projeto.
                </p>

                <p className="lead text-justify">
                    Peça um orçamento e mais informações por <a href="https://wa.me/5511998727617?text=Ol%C3%A1,%20gostaria%20de%20saber%20mais%20sobre%20os%20servi%C3%A7os%20da%20DronDron." target="_blank" rel="noopener noreferrer">whatsapp</a>.
                    Ou então, fique a vontade para deixar uma <a href="mailto:drondron.contact@gmail.com" target="_blank" rel="noopener noreferrer">mensagem</a> com suas dúvidas, sugestões críticas, ou até mesmo curiosidade. Será um prazer atender. Deixe seu e-mail, que retornaremos em breve.
                </p>                

                <p className="lead text-justify">
                    O custo desse serviço varia de acordo com a localização e tamanho da área medidas. Os voos de investigação e pesquisa são realizados nos finais de semana. Verifique a disponibilidade. 
                    <b>ATENÇÃO:</b> O propósito desse serviço é a pesquisa e medição de área para fins de mapeamento, planejamento de divisão e construção civil. Não utilizamos receptores GNSS ou de precisão centimétrica.
                </p>
                <p className="lead text-justify">
                    Caso haja necessidade, geramos ART.
                </p>

                <hr className="my-4" />              
                                
                <br/>

                {/* ---------------------------------------------------------------- */}
                {/* Footer */}
                {/* ---------------------------------------------------------------- */}
                <Footer />

            </div>

        </div>
    );
}