import React from 'react';

import NavBar from '../ui/NavBar';
import Footer from '../ui/Footer';

import ImgInspection_Roof from '../media/Inspection_Roof.webp'
import ImgInspection_Tower from '../media/Inspection_Tower.webp'

export default function Inspection() {

    // useEffect(() => {
    //     ReactGa.initialize('UA-138305011-1')
    //     ReactGa.pageview(window.location.pathname + window.location.search);
    //   }, []);

    return (
        <div className="container">
            
            {/* ---------------------------------------------------------------- */}
            {/* Footer */}
            {/* ---------------------------------------------------------------- */}
            <NavBar />
            
            <div className="jumbotron">

                {/* ---------------------------------------------------------------- */}
                {/* Inspeções */}
                {/* ---------------------------------------------------------------- */}
                <h1 className="display-4 text-center">
                    INSPEÇÕES
                </h1>

                <p className="lead text-justify">
                    Serviço de inspeção com drone: Voos de proximidade e produção de fotos e vídeos com alta qualidade geram material que pode ser utilizado para geração de laudos técnicos ou até mesmo para simples conferência.
                </p>

                <p className="lead text-justify">
                    Requisito obrigatório para garantia de qualidade das avaliações e verificações, ao mesmo tempo que assegura sua equipe evitando que os recursos estejam em situação de risco para as inspeções.
                    Solicite mais informações e orçamento pelo <a href="https://wa.me/5511998727617?text=Ol%C3%A1,%20gostaria%20de%20saber%20mais%20sobre%20os%20servi%C3%A7os%20da%20DronDron." target="_blank" rel="noopener noreferrer">whatsapp</a>.
                    Ou então, fique a vontade para deixar uma <a href="mailto:drondron.contact@gmail.com" target="_blank" rel="noopener noreferrer">mensagem</a> com suas dúvidas, sugestões críticas, ou até mesmo curiosidade. Será um prazer atender. Deixe seu e-mail, que retornaremos em breve.
                </p>

                <br/>

                <img className="img-fluid" src={ImgInspection_Roof} alt="Mapa Coordenadas" />

                <br/>
                <br/>
                <br/>

                <img className="img-fluid" src={ImgInspection_Tower} alt="Mapa Coordenadas" />

                <br/>
                <br/>

                <p className="lead text-justify">
                    O custo desse serviço varia de acordo com a localização e duração das tomadas. Os voos são realizados nos finais de semana, durante a semana os voos são realizados por parceiros. Verifique a disponibilidade.
                </p>

                <hr className="my-4" /> 

                {/* ---------------------------------------------------------------- */}
                {/* Footer */}
                {/* ---------------------------------------------------------------- */}
                <Footer />

            </div>
            
            
        </div>
    );
}