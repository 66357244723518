import React from 'react';

import { useHistory } from 'react-router-dom';

import NavBar from '../ui/NavBar';
import Footer from '../ui/Footer';

import ImgCarousel1 from '../media/1.1-FULL-WIDTH-SLIDESHOWS.jpg';
import ImgCarousel2 from '../media/1.2-FULL-WIDTH-SLIDESHOWS.webp';
import ImgCarousel3 from '../media/1.3-FULL-WIDTH-SLIDESHOWS.JPG';
import ImgCarousel4 from '../media/1.4-FULL-WIDTH-SLIDESHOWS.webp';

import ImgCardTopography from '../media/Card-Topografia-e-Medições.webp';
import ImgCardPhotoVideo from '../media/Card-Imagens aéreas.jpg';
import ImgCardInspection from '../media/Card-Inspection.jpg';
import ImgVideoEdition from '../media/VideoEdition.webp'

import ReactGA from 'react-ga';

export default function Home() {

    function reportSomethingGA() {
        ReactGA.initialize('UA-138305011-1')
        ReactGA.event({
            category: 'category',
            action: 'action'
        })
    }

    const history = useHistory();

    function navigateTopography(e) {
        e.preventDefault();

        history.push('/topography');
    }

    function navigatePhotoVideo(e) {
        e.preventDefault();

        history.push('/photovideo');
    }

    function navigateInspection(e) {
        e.preventDefault();

        history.push('/inspection');
    }

    function navigateVideoEdition(e) {
        e.preventDefault();

        history.push('/videoedition');
    }

    return (
        <div className="container">

            {/* ---------------------------------------------------------------- */}
            {/* Navigation Bar */}
            {/* ---------------------------------------------------------------- */}
            <NavBar />
            
            {/* ---------------------------------------------------------------- */}
            {/* Carousel */}
            {/* ---------------------------------------------------------------- */}
            <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                <ol className="carousel-indicators">
                    <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                </ol>
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src={ImgCarousel1} className="d-block w-100" alt="Carousel 1" />
                    </div>
                    <div className="carousel-item">
                        <img src={ImgCarousel2} className="d-block w-100" alt="Carousel 2" />
                    </div>
                    <div className="carousel-item">
                        <img src={ImgCarousel3} className="d-block w-100" alt="Carousel 3" />
                    </div>
                    <div className="carousel-item">
                        <img src={ImgCarousel4} className="d-block w-100" alt="Carousel 4" />
                    </div>
                </div>
                <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>
            </div>

            {/* ---------------------------------------------------------------- */}
            {/* INTRO Text */}
            {/* ---------------------------------------------------------------- */}
            <div className="jumbotron">

                <h1 className="display-4">SERVIÇOS</h1>

                <br />

                <p className="text-justify lead"><b>CONFIRA OS SERVIÇOS PRESTADOS PELA DronDron.</b> Aqui você vai encontrar tudo que precisa. Equipamentos de última geração e softwares de ponta, com cobertura para o que existe de mais novo no mercado atual. Estamos constantemente crescendo e ampliando nossas opções. Se não encontrar o que está procurando, por favor entre em contato.</p>

                {/* ---------------------------------------------------------------- */}
                {/* Service Cards */}
                {/* ---------------------------------------------------------------- */}

                <br />

                <div className="card">
                    <img src={ImgVideoEdition} className="card-img-top" alt="Fotos e vídeos aéreos" />
                    <div className="card-body">
                        <h5 className="card-title">Edição de vídeos</h5>
                        <p className="card-text text-justify">Todo material de fotos e vídeos captados na DronDron (ou não) podem ser enviados para edição. Trabalhamos com a produção de restrospectivas para aniversários, casamentos, formaturas e outras ocasiões. Tembém editamos material para Youtube, Instagram, IGTV e redes sociais. Serviço essencial para marketing e divulgação de produtos com vídeos promocionais, hoje em dia é indispensável ter um material multimídia para qualquer tipo de negócio.</p>
                        <button type="button" class="btn btn-outline-dark" onClick={navigateVideoEdition}>Saiba mais</button>
                    </div>
                </div>

                <br />

                <div className="card">
                    <img src={ImgCardPhotoVideo} className="card-img-top" alt="Fotos e vídeos aéreos" />
                    <div className="card-body">
                        <h5 className="card-title">Fotos e vídeos aéreos</h5>
                        <p className="card-text text-justify">Vídeos institucionais para empresas, coberturas de festas, eventos.
                        Filmagens e fotos aéreas de eventos, festas, lançamento de imóveis, terrenos, condomínios.
                        Fotografias com drone de plantas industriais, agronegócio, hotéis e festas.
                                Edição de vídeo profissional, produção final. Indispensável para campanhas de marketing. Melhor custo x benefício. Estrutura Completa.</p>
                        <button type="button" class="btn btn-outline-dark" onClick={navigatePhotoVideo}>Saiba mais</button>
                    </div>
                </div>

                <br />

                <div className="card">
                    <img src={ImgCardTopography} className="card-img-top" alt="Topografia e Medições" />
                    <div className="card-body">
                        <h5 className="card-title">Topografia e Medições</h5>
                        <p className="card-text text-justify"><b>DRONE</b> a nova solução prática, segura e barata para fotos e filmagens aéreas com edição profissional, medições e aerofotogrametria (urbana e agrícola), medições e topografia, inspeções de todos tipos, inclusive prediais, construção civil e obras. Aerofotogrametria é o nome dado ao método de obtenção de dados topográficos por meio de fotografias aéreas, geralmente, com o fim de mapeamento e medições.
                            Uma aeronave equipada com câmeras fotográficas métricas percorre o território fotografando-o verticalmente, seguindo alguns preceitos técnicos como: ângulo máximo de cambagem, sobreposição frontal e lateral entre as fotos.
                            Com esses recursos se pode executar medições precisas utilizando de fotografias métricas.</p>
                        <button type="button" class="btn btn-outline-dark" onClick={navigateTopography}>Saiba mais</button>
                    </div>
                </div>

                <br />

                <div className="card">
                    <img src={ImgCardInspection} className="card-img-top" alt="Fotos e vídeos aéreos" />
                    <div className="card-body">
                        <h5 className="card-title">Inspeções</h5>
                        <p className="card-text text-justify">Fotos e vídeos em alta resolução possibilitam a visualização dos detalhes com bastante nitidez para detecção de problemas e manutenção preventiva.</p>
                        <button type="button" class="btn btn-outline-dark" onClick={navigateInspection}>Saiba mais</button>
                    </div>
                </div>

                <br />

                <hr className="my-4" />

                {/* ---------------------------------------------------------------- */}
                {/* Footer */}
                {/* ---------------------------------------------------------------- */}
                <Footer />

            </div>

        </div>

    );
}