import React from 'react';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Home from './pages/Home';
import PhotoVideo from './pages/PhotoVideo';
import Topography from './pages/Topography';
import VideoEdition from './pages/VideoEdition';
import Portfolio from './pages/Portfolio';
import Inspection from './pages/Inspection';

import ScrollIntoView from "./ui/ScrollIntoView";

import withTracker from "./services/withTracker";

export default function Routes() {

    return(
        <BrowserRouter>
                <ScrollIntoView>
                    <Switch>
                        <Route path="/" exact component={withTracker(Home, { /* additional attributes */ } )} /> />
                        <Route path="/photovideo" component={withTracker(PhotoVideo, { /* additional attributes */ } )} /> />
                        <Route path="/topography" component={withTracker(Topography, { /* additional attributes */ } )} /> />
                        <Route path="/videoedition" component={withTracker(VideoEdition, { /* additional attributes */ } )} /> />
                        <Route path="/portfolio" component={withTracker(Portfolio, { /* additional attributes */ } )} /> />
                        <Route path="/inspection" component={withTracker(Inspection, { /* additional attributes */ } )} /> />             
                    </Switch>
                </ScrollIntoView>
        </BrowserRouter>
    );
}